import React, { useState, useEffect } from "react";
import disclaimerText from "./constants/DisclaimerText";
import useStyles from "./DisclaimerBanner.styles";
import { IModal, useUuiContext } from "@epam/uui-core";
import {
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  FlexRow,
  FlexSpacer,
  Panel,
  ScrollBars,
  Text,
  Button,
  RichTextView,
  Checkbox,
} from "@epam/uui";

function DisclaimerBannerComponent(props: IModal<string>) {
  const classes = useStyles();
  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  const handleClose = () => {
    sessionStorage.setItem("disclaimerAgreed", "true");
  };

  return (
    <ModalBlocker disallowClickOutside {...props} disableCloseByEsc={true}>
      <ModalWindow cx={classes.disclaimer}>
        <Panel background="surface-main">
          <ModalHeader title="Disclaimer" borderBottom />
          <ScrollBars hasTopShadow hasBottomShadow>
            <FlexRow padding="24" vPadding="12">
              <RichTextView>
                <Text size="36">{disclaimerText.header.text}</Text>
                <ul>
                  {disclaimerText.body.map(({ listBold, listText }) => (
                    <li>
                      <b>{listBold}</b> {listText}
                    </li>
                  ))}
                </ul>
                <Text size="36">{disclaimerText.footer.text}</Text>
                <Checkbox
                  value={checked}
                  onValueChange={handleChange}
                  label="I agree with terms and conditions"
                />
              </RichTextView>
            </FlexRow>
          </ScrollBars>
          <ModalFooter>
            <FlexSpacer />
            <Button
              color="primary"
              isDisabled={!checked}
              caption="Proceed"
              onClick={() => {
                props.success("Success action");
                handleClose();
              }}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
}
export default function DisclaimerBanner() {
  const { uuiModals } = useUuiContext();
  const isDisclaimerVisible = sessionStorage.getItem("disclaimerAgreed");

  useEffect(() => {
    !isDisclaimerVisible &&
      uuiModals
        .show((props) => <DisclaimerBannerComponent {...props} />)
        .catch(() => {});
  }, [uuiModals]);

  return null;
}

