import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import Tooltip from "@mui/material/Tooltip";
import List from "@mui/material/List";
import ReceiverInSendList, {
  generateReceiverKey,
} from "./component/ReceiverInSendList";
import ReceiverApproveDialog from "./dialog/ReceiverApproveDialog";
import { useSnackbar } from "notistack";
import makeStyles from "@mui/styles/makeStyles";
import { arrayRemove } from "./util/Everything";
import { MenuItem, Select } from "@mui/material";
import { ADMIN } from "../ListItems";
import { api } from "../api/api";
import ReceiverAutocomplete from "../components/ReceiverAutocomplete";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: 400,
    marginTop: theme.spacing(3),
    marginLeft: "auto",
    marginRight: "auto",
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
}));

export default function SenderView(props) {
  useEffect(() => {
    props.setShowSearch(false);
    api.secretSanta
      .getState()
      .then((res) => {
        setSantaState(res.intValue);
      })
      .catch((error) => {
        props.onFetchFailed(error);
      });
  }, []);

  const classes = useStyles();

  const [selectedReceivers, setSelectedReceivers] = useState([]);
  const [santaState, setSantaState] = useState(0);

  const { enqueueSnackbar } = useSnackbar();

  const [emailType, setEmailType] = useState("regular");

  const emailTypes = () => {
    let res = [
      { value: "regular", name: "Regular" },
      { value: "softlanding", name: "Soft landing" },
      { value: "aegon", name: "AEGON card" },
    ];
    if (props.user.roles.includes(ADMIN) && santaState === 3) {
      res.push({ value: "santa_present", name: "Secret Santa" });
    }

    return res;
  };

  const handleEmailTypeChange = (e) => {
    setEmailType(e.target.value);
  };

  const snackBarAddMessage = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  function handleReceiverEmailTypeChange(receiver) {
    return (value) => {
      receiver.emailSendType = value;
    };
  }

  const handleReceiverAdd = (receiver) => {
    let receivers = selectedReceivers.slice(0, selectedReceivers.length);
    receiver.emailSendType = emailType;
    receivers.push(receiver);
    setSelectedReceivers(receivers);
  };

  const handleOnItemClear = (receiver) => {
    let receivers = selectedReceivers.slice(0, selectedReceivers.length);
    receivers = arrayRemove(receivers, receiver);
    setSelectedReceivers(receivers);
  };

  const handleOnSend = () => {
    setDialogOpen(true);
  };

  //================ Dialog ========================
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogApprove = () => {
    setDialogOpen(false);
    selectedReceivers.forEach((receiver) => {
      api.sender
        .send(receiver.emailSendType, receiver.id)
        .then((text) => {
          addMessageToSnack(receiver, text);
        })
        .catch((error) => {
          props.onFetchFailed(error);
        });
    });
    setSelectedReceivers([]);
  };

  const addMessageToSnack = (receiver, result) => {
    const type = result === `"OK"` ? "success" : "info";
    let emailType = emailTypes().find(
      (res) => res.value === receiver.emailSendType
    ).name;
    const message =
      result === `"OK"`
        ? `Sending ${emailType} email to ${receiver.name}`
        : `Email to ${receiver.name} has already been sent today`;
    snackBarAddMessage(message, type);
  };

  //================ /Dialog ========================

  let selectedView;
  if (selectedReceivers.length > 0) {
    selectedView = (
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs>
            <List>
              {selectedReceivers.map((receiver) => (
                <ReceiverInSendList
                  key={generateReceiverKey(receiver)}
                  receiver={receiver}
                  handleOnItemClear={handleOnItemClear}
                  emailTypes={emailTypes()}
                  handleCurrentEmailTypeChange={handleReceiverEmailTypeChange(
                    receiver
                  )}
                  changeCurrentEmailType={true}
                />
              ))}
            </List>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <div>
      <ReceiverApproveDialog
        component={"span"}
        open={dialogOpen}
        handleClose={handleDialogClose}
        handleApprove={handleDialogApprove}
        handleOnItemClear={handleOnItemClear}
        emailTypes={emailTypes()}
        receivers={selectedReceivers}
      />

      <Grid container direction="column" spacing={1}>
        <Grid item xs>
          <Paper className={classes.paper}>
            {" "}
            {/*className={fixedSenderPaperSize}*/}
            <Grid container spacing={7}>
              {/*autocomplete + button container*/}
              <Grid item xs={9}>
                <ReceiverAutocomplete
                  selectedReceivers={selectedReceivers}
                  handleReceiverAdd={handleReceiverAdd}
                  onFetchFailed={props.onFetchFailed}
                />
              </Grid>
              <Grid item xs={3}>
                <Tooltip title="Send">
                  <IconButton
                    color="success"
                    aria-label="Send notifications"
                    component="span"
                    disabled={selectedReceivers.length === 0}
                    onClick={handleOnSend}
                    size="large"
                  >
                    <SendIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Select
                  variant="standard"
                  value={emailType}
                  onChange={handleEmailTypeChange}
                  fullWidth
                >
                  {emailTypes().map((res) => (
                    <MenuItem key={res.value} value={res.value}>
                      {res.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs>
          {selectedView}
        </Grid>
      </Grid>
    </div>
  );
}
