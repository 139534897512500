import {SantaPresentDeliveryStatus} from "./models";

export const transcribeSantaDeliveryStatus = (deliveryStatus: SantaPresentDeliveryStatus) => {
    switch (deliveryStatus) {
        case "NOT_DELIVERED":
            return "Not Delivered"
        case "MARKED_AS_DELIVERED":
            return "Marked as Delivered"
        case "DELIVERY_CONFIRMED":
            return "Delivery Confirmed"
        case "DELIVERY_DOUBLE_CHECKED":
            return "Delivery Double Checked"
        case "NOTIFICATION_SENT":
            return "Notification was sent"

    }
}

export const lowerCaseAllWordsExceptFirstLetters = (string: string) =>
    string.replaceAll(/\S*/g, word =>
        `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
    );
