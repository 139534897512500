import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

export default function StringArrayList(props) {
  return (
    <List sx={{ display: "flex", flexDirection: "column" }}>
      {props.strings.map((string) => {
        return (
          <ListItem key={string}>
            <ListItemText
              primary={props.stringChange ? props.stringChange(string) : string}
              primaryTypographyProps={{ fontSize: "0.875rem" }}
            />
          </ListItem>
        );
      })}
    </List>
  );
}
