import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import ReceiverDetailView from "../component/ReceiverDetailView";

export default function ReceiverDetailDialog(props) {
    const title = props.receiver ? props.receiver.name : "New receiver creation";


    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            scroll={'paper'}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <ReceiverDetailView
                    receiverId={(props.receiver) ? props.receiver.id : null}
                    handleClose={props.handleClose}
                    {...props}
                />
            </DialogContent>
            <DialogActions/>
        </Dialog>
    );
}
