import { UuiError, useUuiContext } from "@epam/uui-core";
import React, { FC } from "react";
import { LinkButton } from "@epam/uui";
import { Paths } from "../../router/paths";
import Styled from "./ErrorBoundary.styles";

const ErrorBoundary: FC = () => {
  const { uuiErrors } = useUuiContext();

  return (
    <>
      {uuiErrors.reportError(
        new UuiError({
          status: 404,
          title: (
            <>
              <Styled.Title>Oooops! We couldn't find this page</Styled.Title>
              <Styled.Text>Sorry for the inconvenience</Styled.Text>
            </>
          ),
          subtitle: (
            <>
              <LinkButton
                caption="Go to Couriers"
                onClick={() => window.location.href = Paths.Courier}
              />
            </>
          ),
        })
      )}
    </>
  );
};

export { ErrorBoundary };
