import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  checkbox: {
    paddingLeft: "24px",
    paddingBottom: "9px",
  },
  disclaimer: {
    width: "80vw!important",
  },
}));

export default useStyles;
