import React, {useEffect, useState} from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableView from "./util/TableView";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {deepPurple, green, lightBlue, yellow} from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {api} from "../api/api";
import SecretSantaAddCustomPresentDialog from "./dialog/SecretSantaAddCustomPresentDialog";
import Tooltip from "@mui/material/Tooltip";
import {transcribeSantaDeliveryStatus} from "../api/helper";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import TextField from "@mui/material/TextField";


export default function SecretSantasView(props) {
    const [data, setData] = useState([]);

    const [openAddSantaDialog, setOpenAddSantaDialog] = useState(false);
    const [addSantaApproval, setAddSantaApproval] = useState(false);

    useEffect(() => {
        props.setShowSearch(true);
    }, []);

    useEffect(() => {
        if (addSantaApproval) {
            setOpenAddSantaDialog(false)
            setAddSantaApproval(false)
        }
    }, [addSantaApproval]);


    const handleOnChangeDeliveryStatus = (receiverId, index) => {
        (async () => {
            api.secretSanta.setNextDeliveryStatus(receiverId)
                .then(santa => {
                    let newArr = data.slice(0, data.length);
                    newArr[index] = santa;
                    setData(newArr);
                })
                .catch((error) => {
                    props.onFetchFailed(error);
                })
        })();
    };

    const handleOnPresentsCountChange = (receiverId, index, presentsCount) => {
        if (presentsCount < 1) {
            presentsCount = 1
        }
        if (data[index].presentsCount !== presentsCount) {
            (async () => {
                api.secretSanta.setPresentsCount(receiverId, presentsCount)
                    .then(santa => {
                        let newArr = data.slice(0, data.length);
                        newArr[index] = santa;
                        setData(newArr);
                    })
                    .catch((error) => {
                        // props.onFetchFailed(error);
                    });
            })();
        }
    };

    const tableHeader =
        <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Receiver's Name</TableCell>
            <TableCell style={{ width: 50 }}>Presents count</TableCell>
            <TableCell>Santa's Name</TableCell>
            <TableCell>Receiver's Wishes</TableCell>
        </TableRow>;

    const tableRows = (receiver, index) => {

        let notDelivered = receiver.deliveryStatus === "NOT_DELIVERED";
        let markedDelivered = receiver.deliveryStatus === "MARKED_AS_DELIVERED";
        let deliveryConfirmed = receiver.deliveryStatus === "DELIVERY_CONFIRMED";
        let deliveryDoubleChecked = receiver.deliveryStatus === "DELIVERY_DOUBLE_CHECKED";
        let notificationSent = receiver.deliveryStatus === "NOTIFICATION_SENT";
        let color = (markedDelivered && {color: yellow[700]})
            || (deliveryConfirmed && {color: green[500]})
            || (deliveryDoubleChecked && {color: deepPurple[500]})
            || (notificationSent && {color: lightBlue[500]})
        let icon = notDelivered ? <CheckBoxOutlineBlankIcon/> : < CheckBoxIcon sx={color}/>

        return <TableRow key={receiver.id}>
            <TableCell>{
                <Tooltip title={transcribeSantaDeliveryStatus(receiver.deliveryStatus)}>
                    <IconButton
                        onClick={() => handleOnChangeDeliveryStatus(receiver.id, index)}
                    >
                        {icon}
                    </IconButton>
                </Tooltip>
            }
            </TableCell>
            <TableCell>{receiver?.participant.name}</TableCell>
            <TableCell style={{ width: 50 }}>
                <TextField type="number"
                           variant="standard"
                           value={receiver.presentsCount}
                           onChange={(e) => handleOnPresentsCountChange(receiver.id, index, e.target.value)}
                />
            </TableCell>
            <TableCell>{receiver?.santa?.name}</TableCell>
            <TableCell>{receiver?.participantWishes}</TableCell>

        </TableRow>
    };


    return (
        <>
            <SecretSantaAddCustomPresentDialog
                component={'span'}
                open={openAddSantaDialog}
                handleClose={() => setOpenAddSantaDialog(false)}
                handleApprove={() => setAddSantaApproval(true)}
                onFetchFailed={props.onFetchFailed}
            />
            <Fab color="primary" aria-label="add" variant="extended"
                 sx={{
                     margin: 0,
                     top: 'auto',
                     right: 20,
                     bottom: 20,
                     left: 'auto',
                     position: 'fixed'
                 }}
                 onClick={() => setOpenAddSantaDialog(true)}
            >
                <AddIcon sx={{mr: 1}}/>
                Add Custom Presents
            </Fab>
            <TableView
                totalUrl={"secretsanta/total"}
                fetchUrl={"secretsanta/all"}
                tableHeader={tableHeader}
                tableRows={tableRows}
                searchValue={props.searchValue}
                data={data}
                setData={setData}
                {...props}
            />
        </>
    );
}
