import React from 'react';
import Typography from '@mui/material/Typography';

export default function DisplayMessage(props) {
    return (
        <Typography variant="h6" gutterBottom color="error">
            {props.message}
        </Typography>
    )
}
