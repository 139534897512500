export default function transformCountryWithCities(
  countryData: any,
  cityData: any
) {
  return countryData.map((country: any) => {
    let cities = cityData
      .filter((city: any) => city.countryCode === country.countryCode)
      .map((city: any) => ({ ...city, id: city.name }));

    cities = [...cities, { id: "other", name: "Other" }];

    return {
      ...country,
      id: country.countryCode,
      cityList: cities,
    };
  });
}
