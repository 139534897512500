import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import MainScreen from "../MainScreen";
import { Paths } from "./paths";
import { ErrorBoundary } from "../components/ErrorBoundary/ErrorBoundary";

export const RouterConfig = () => (
  <Router>
    <Routes>
      <Route errorElement={<ErrorBoundary />} element={<MainScreen />} path="/">
        <Route path={Paths.Courier} element={<MainScreen />} />
        <Route path={Paths.User} element={<MainScreen />} />
        <Route path={Paths.Admin} element={<MainScreen />} />
        <Route path={Paths.Dashboard} element={<MainScreen />} />
        <Route path={Paths.Send} element={<MainScreen />} />
        <Route path={Paths.CourierDetails} element={<MainScreen />} />
        <Route path={Paths.History} element={<MainScreen />} />
        <Route path={Paths.SecretSanta} element={<MainScreen />} />
        <Route path={Paths.Receivers} element={<MainScreen />} />
        <Route path={Paths.Users} element={<MainScreen />} />
        <Route path="*" element={<ErrorBoundary />} />
      </Route>
    </Routes>
  </Router>
);
