import isInternationalType from "./IsInternational";
import { isEmpty } from "lodash";
import isOther from "./isOther";

const getCountryValue = (
  courier: any,
  field: string,
  isDestination = false
) => {
  if (isDestination && !isInternationalType(courier?.destinationType)) {
    return;
  }

  if (isEmpty(courier)) {
    return;
  }
  if (courier[field]) {
    return courier[field];
  }
  return "other";
};

const getCityValue = (courier: any, field: string, cityList: any) => {
  const countryField =
    !isInternationalType(courier?.destinationType) || field.includes("source")
      ? "sourceCountryCode"
      : "destinationCountryCode";

  const countryValue = getCountryValue(
    courier,
    countryField,
    countryField.includes("destination")
  );

  if (isEmpty(courier) || isOther(countryValue)) {
    return;
  }

  const isCityExist = cityList.some(
    // TODO: Replace on comparison by slug
    (city: any) => city.name.toLowerCase() === courier?.[field].toLowerCase()
  );

  if (isCityExist) {
    return courier[field];
  }

  return "other";
};

export { getCityValue, getCountryValue };
