import Button from "@mui/material/Button";
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from 'react';
import DisplayMessage from "../util/DisplayMessage";
import RedeemIcon from '@mui/icons-material/Redeem';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import {api} from "../../api/api";
import {useSnackbar} from "notistack";
import SecretSantaBecameConsentDialog from "../dialog/SecretSantaBecameConsentDialog";
import Box from "@mui/material/Box";
import SecretSantaPresentDeliveryConsentDialog from "../dialog/SecretSantaPresentDeliveryConsentDialog";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            padding: theme.spacing(2),
        },
    },
    button: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    hintTitle: {
        marginTop: theme.spacing(3),
    },

}));

export default function SecretSanta(props) {
    const classes = useStyles();

    const [checkIsSanta, setCheckIsSanta] = useState(true);
    const [isSanta, setIsSanta] = useState(false);
    const [santaRunning, setSantaRunning] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [wishes, setWishes] = useState('');
    const [santaState, setSantaState] = useState(undefined);
    //santa became consent dialog
    const [santaConsentDialog, openSantaConsentDialog] = useState(false);
    const [consentApprove, setConsentApproval] = useState(false);
    //santa present delivery consent dialog
    const [santaPresentDeliveryConsentDialog, openSantaPresentDeliveryConsentDialog] = useState(false);
    const [presentDeliveryConsentApprove, setPresentDeliveryConsentApproval] = useState(false);

    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (checkIsSanta) {
            (async () => {
                await api.secretSanta.getCurrentUserData()
                    .then(santaState => {
                        setSantaState(santaState)
                        setSantaRunning(santaState.santaState !== 0)

                        setWishes(santaState.santaWishes)
                        setIsSanta(santaState.santa)
                        setCheckIsSanta(false)
                        setErrorMessage(undefined)
                    }).catch(() => {
                        setErrorMessage("An error has occurred checking if you are Santa! :(")
                        setCheckIsSanta(false)
                    });
            })();
        }
    }, [checkIsSanta]);

    useEffect(() => {
        if (consentApprove) {
            becomeSanta()
        }
    }, [consentApprove]);

    useEffect(() => {
        if (presentDeliveryConsentApprove) {
            markDelivered()
        }
    }, [presentDeliveryConsentApprove]);


    const becomeSanta = () => {
        api.secretSanta.become()
            .then(() => {
                setCheckIsSanta(true);
                setErrorMessage(undefined);
                setConsentApproval(false);
                openSantaConsentDialog(false);
            })
            .catch(() => {
                setErrorMessage("An error has occurred, please try again!")
            });
    };

    const unbecomeSanta = () => {
        api.secretSanta.unbecome()
            .then(() => {
                setCheckIsSanta(true);
                setErrorMessage(undefined);
            })
            .catch(() => {
                setErrorMessage("An error has occurred, please try again!")
            });
    };

    const markDelivered = () => {
        api.secretSanta.markDeliveredByUser()
            .then(() => {
                setCheckIsSanta(true);
                setErrorMessage(undefined);
                setPresentDeliveryConsentApproval(false);
                openSantaPresentDeliveryConsentDialog(false);
            })
            .catch(() => {
                setErrorMessage("An error has occurred, please try again!")
            });
    };

    const handleOnSave = () => {
        api.secretSanta.wishes(wishes)
            .then(() => {
                setCheckIsSanta(true)
                snackBarAddMessage(`Your wishes were successfully saved`, 'success')
            })
            .catch((error) => {
                props.onFetchFailed(error);
            })
    };

    const snackBarAddMessage = (message, variant) => {
        enqueueSnackbar(message, {variant});
    };

    const disableButtons = santaState?.santaState !== 1

    const presentDelivery = (deliveryStatus) => {
        switch (deliveryStatus) {
            case "NOT_DELIVERED":
            case "MARKED_AS_DELIVERED":
                return <Button
                    variant="contained"
                    color="success"
                    onClick={() => openSantaPresentDeliveryConsentDialog(true)}
                    disabled={santaState.deliveryStatus !== "NOT_DELIVERED"}
                    startIcon={<RedeemIcon/>}
                >
                    I put present under the Christmas tree!
                </Button>
            case "DELIVERY_CONFIRMED":
            case "DELIVERY_DOUBLE_CHECKED":
                return <Typography variant="h5" sx={{color: 'green'}}><RedeemIcon/> Present delivery was confirmed by reindeer!</Typography>
            case "NOTIFICATION_SENT":
                return <Typography variant="h5" sx={{color: 'blue'}}><RedeemIcon/> {santaState.receiver?.name} was notified about the
                    present!</Typography>
        }
    };


    return <>
        {santaRunning &&
            <Paper className={classes.paper}>
                <SecretSantaBecameConsentDialog
                    component={'span'}
                    open={santaConsentDialog}
                    handleClose={() => openSantaConsentDialog(false)}
                    handleApprove={() => setConsentApproval(true)}
                />
                <Typography variant="h4" gutterBottom>
                    Secret Santa
                </Typography>
                {santaState.receiver ? <Box>
                        <SecretSantaPresentDeliveryConsentDialog
                            component={'span'}
                            open={santaPresentDeliveryConsentDialog}
                            handleClose={() => openSantaPresentDeliveryConsentDialog(false)}
                            handleApprove={() => setPresentDeliveryConsentApproval(true)}
                        />
                        <Typography variant="h5" color="primary">
                            You are the Santa for <b>{santaState.receiver?.name}</b>!
                        </Typography>
                        <br/>
                        <Typography>
                            Don't forget to pack present, put receiver's name on it and place the present under the Christmas tree on the office's 2nd
                            floor BEFORE the evening of 19th of December, the sooner the better!
                        </Typography>
                        <Typography>
                            <b>Here are some hints for the present from {santaState.receiver?.name}:</b>
                        </Typography>
                        <br/>
                        <Typography variant="h6" color="textSecondary" style={{whiteSpace: 'pre-line'}}>
                            {santaState.receiverWishes}
                        </Typography>
                        <br/>
                        {presentDelivery(santaState.deliveryStatus)}
                        <br/>

                    </Box>
                    : <div className={classes.paper}>
                        {errorMessage ? <DisplayMessage message={errorMessage}/> : false}

                        <div>

                            {!isSanta ?

                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => openSantaConsentDialog(true)}
                                    disabled={disableButtons}
                                    endIcon={<RedeemIcon/>}
                                >
                                    Become Santa!
                                </Button> :
                                <><Button
                                    variant="contained"
                                    onClick={unbecomeSanta}
                                    disabled={disableButtons}
                                    endIcon={<SentimentDissatisfiedIcon/>}
                                >
                                    Unbecome Santa
                                </Button>

                                    <Typography
                                        // variant="body1"
                                        gutterBottom
                                        className={classes.hintTitle}
                                    >
                                        Write a letter to your Santa with some ideas on what you would like to receive as a present (Not
                                        guaranteed but will be a hint):
                                    </Typography>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        id='wishes'
                                        name="wishes"
                                        label="I wish..."
                                        fullWidth
                                        multiline
                                        disabled={disableButtons}
                                        rows={4}
                                        value={wishes}
                                        onChange={(event) => {
                                            setWishes(event.target.value)
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleOnSave}
                                        className={classes.button}
                                        disabled={disableButtons || wishes === santaState.santaWishes}
                                    >
                                        Save
                                    </Button>
                                </>}
                        </div>
                    </div>
                }
                <br/>
                <Link
                    color="primary"
                    href="https://kb.epam.com/display/GDOKB/Malaga+Secret+Santa"
                    target="_blank"
                    underline="hover">
                    For more information welcome to our KB!
                </Link>
            </Paper>}

    </>;
}
