// export const baseUrl = baseUrlFunc();

const SERVER_URL = process.env.REACT_APP_SERVER_URL || "";

export function baseUrlFunc() {

    const port = window.location.port === '3000' ? `:8090` : `:${window.location.port}`;
    const host = SERVER_URL !== "" ? SERVER_URL : `${window.location.protocol}//${window.location.hostname}${port}`
    // const host = `${window.location.protocol}//${window.location.hostname}${port}`
    return `${host}/`;
}
