import React, { useEffect, useState } from "react";
import TableView from "./util/TableView";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Moment from "react-moment";
import StringArrayList from "./util/StringArrayList";
import Button from "@mui/material/Button";
import { api } from "../api/api";
import { useSnackbar } from "notistack";
import { ADMIN, SENDER } from "../ListItems";

export default function HistoryView(props) {
  const [data, setData] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const snackBarAddMessage = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  useEffect(() => {
    props.setShowSearch(false);
  }, [props]);

  const tableHeader = (
    <TableRow>
      <TableCell>Name</TableCell>
      <TableCell>Emails</TableCell>
      <TableCell>Date</TableCell>
      <TableCell>Sender</TableCell>
      <TableCell>Type</TableCell>
      <TableCell>Status</TableCell>
      <TableCell>Actions</TableCell>
    </TableRow>
  );

  const tableRows = (row, index) => {
    let emails = [row.receiver.epamEmail, ...row.receiver.emails];
    return (
      <TableRow key={row.id}>
        <TableCell>{row.receiver.name}</TableCell>
        <TableCell>
          <StringArrayList strings={emails} />
        </TableCell>
        <TableCell>
          <Moment date={row.date} format="HH:mm:SS DD/MM/YYYY" />
        </TableCell>
        <TableCell>{row.sender.name}</TableCell>
        <TableCell>{row.type.toLowerCase().replaceAll("_", " ")}</TableCell>
        <TableCell>{row.status.toLowerCase().replaceAll("_", " ")}</TableCell>
        <TableCell>
          <div>
            {row.status === "NOTIFICATION_SENT" &&
              row.type !== "REMINDER" &&
              row.type !== "COURIER_MATCH" && (
                <div>
                  <Button
                    onClick={() => {
                      callMarkCollected(row.id, index);
                    }}
                    color="primary"
                  >
                    {`Collected`}
                  </Button>

                  {(props.user.roles.includes(ADMIN) ||
                    props.user.roles.includes(SENDER)) &&
                    row.type === "REGULAR" && (
                      <Button
                        onClick={() => {
                          callSendReminder(row.id);
                        }}
                        color="secondary"
                      >
                        {`Remind`}
                      </Button>
                    )}
                </div>
              )}
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const callMarkCollected = (id, index) => {
    (async () => {
      api.history
        .markCollected(id)
        .then((log) => {
          let newArr = data.slice(0, data.length);
          newArr[index] = log;
          setData(newArr);
          snackBarAddMessage("Marked as collected", "success");
        })
        .catch((err) => {
          switch (err.status) {
            case 403:
              snackBarAddMessage(
                "You don't have permission to do that!",
                "error"
              );
              break;
            case 404:
              snackBarAddMessage(
                "Record doesn't exists, please refresh page",
                "error"
              );
              break;
            default:
          }
        });
    })();
  };

  const callSendReminder = (id) => {
    (async () => {
      api.sender
        .sendReminder(id)
        .then((_) => {
          snackBarAddMessage("Reminder sent", "success");
        })
        .catch((err) => {
          switch (err.status) {
            case 403:
              snackBarAddMessage(
                "You don't have permission to do that!",
                "error"
              );
              break;
            case 404:
              snackBarAddMessage(
                "Record doesn't exists, please refresh page",
                "error"
              );
              break;
            default:
          }
        });
    })();
  };

  let currentUserUrlAddition = props.userId ? "/currentUser" : "";

  return (
    <TableView
      totalUrl={"history/sent-letters" + currentUserUrlAddition + "/total"}
      fetchUrl={"history/sent-letters" + currentUserUrlAddition}
      tableHeader={tableHeader}
      tableRows={tableRows}
      data={data}
      setData={setData}
      {...props}
    />
  );
}
