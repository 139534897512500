import React, {useEffect} from 'react';
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import Grid from "@mui/material/Grid";
import SecretSantaAdmin from "./component/SecretSantaAdmin";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },

}));

export default function AdminView(props) {

    useEffect(() => {
        props.setShowSearch(false);
    }, []);

    return (
        <Paper>
            <Grid item xs={12}>
                <SecretSantaAdmin/>
            </Grid>
        </Paper>
    );
}
