import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {api} from "../../api/api";


export default function Copyright() {
    const [version, setVersion] = useState("");


    useEffect(() => {
        if (version !== "") {
            return undefined;
        }
        (async () => {
            await api.any.version()
                .then(versionResp => {
                    setVersion(versionResp.version);

                })
                .catch((error) => {
                });
        })();
    }, [version]);

    const currentYear = (new Date().getFullYear())
    const yearTxt = `2019 - ${currentYear}`
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            © {yearTxt} Hermes v.{version}
        </Typography>
    );
}
