export enum Paths {
  Courier = "/couriers",
  User = "/user",
  Admin = "/admin",
  Dashboard = "/dashboard",
  Send = "/send",
  CourierDetails = "/couriers/:id",
  History = "/history",
  SecretSanta = "/secretSantas",
  Receivers = "/receivers",
  Users = "/users",
}
