import React, { useState } from "react";
import { IModal } from "@epam/uui-core";
import {
  ModalBlocker,
  ModalHeader,
  ModalWindow,
  FlexRow,
  Panel,
  ScrollBars,
  TabButton,
} from "@epam/uui";
import "./UserGuideModal.css";
import {
  MainText,
  RequestText,
  ResponseText,
  UserInfoText,
} from "./components/UserGuideTabsContent";

export default function UserGuideModal(modalProps: IModal<string>) {
  const [value, onValueChange] = useState("Main");
  return (
    <ModalBlocker {...modalProps}>
      <ModalWindow cx={"guideContainer"}>
        <Panel background="surface-main">
          <ModalHeader
            title="How To guide"
            onClose={() => modalProps.success("")}
          />
          <ScrollBars hasTopShadow hasBottomShadow>
            <FlexRow padding="24" cx>
              <TabButton
                cx="tabBtn"
                caption="What is Hermes?"
                isLinkActive={value === "Main"}
                onClick={() => onValueChange("Main")}
                size="36"
              />
              <TabButton
                cx="tabBtn"
                caption="How to create a request? "
                isLinkActive={value === "Request"}
                onClick={() => onValueChange("Request")}
                size="36"
              />
              <TabButton
                cx="tabBtn"
                caption="How to find people, who can help me?"
                isLinkActive={value === "Response"}
                onClick={() => onValueChange("Response")}
                size="36"
              />
              <TabButton
                cx="tabBtn"
                caption="How to view accepted requests?"
                isLinkActive={value === "UserInfo"}
                onClick={() => onValueChange("UserInfo")}
                size="36"
              />
            </FlexRow>
            <FlexRow padding="24">
              {value === "Main" && <MainText />}
              {value === "Request" && <RequestText />}
              {value === "Response" && <ResponseText />}
              {value === "UserInfo" && <UserInfoText />}
            </FlexRow>
          </ScrollBars>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
}
