import React from "react";
import { Text, RichTextView, FlexRow, FlexCell, LinkButton } from "@epam/uui";
import "../UserGuideModal.css";

export function MainText() {
  return (
    <RichTextView>
      <h5>What is Hermes?</h5>
      <Text>
        Hermes module is a tool for collaboration among EPAMers, enabling them
        to assist each other with the sending and receiving of parcels and
        documents between various cities and countries.
      </Text>
      <Text>
        It allows you to:
        <ul>
          <li>send you parcel from your country to another.</li>
          <li>help others in transporting those parcels.</li>
        </ul>
      </Text>
    </RichTextView>
  );
}

export function RequestText() {
  return (
    <RichTextView>
      <h5>
        How to create a request to send a parcel or support in delivering it?
      </h5>
      <ol>
        <li>
          Click the <b>Create</b> button.
        </li>
        <li>
          In the <b>I am</b> field, select:
          <ul>
            <li>
              <b>Sender</b> if you wish to send a parcel,
            </li>
            <li>
              <b>Courier</b> if you are available to help with transporting
              parcels.
            </li>
          </ul>
        </li>
        <li>Fill in the rest of the required fields.</li>
        <li>
          Be sure to indicate your availability in the <b>Valid until</b> field.
        </li>
        <li>If necessary, include additional details in the comments.</li>
        <li>
          Click the <b>Save</b> button, to submit your request
        </li>
      </ol>
      <Text>
        Congratulations, your request has been created! Now, all you need to do
        is wait for another kind EPAMer to respond to it.
      </Text>
      <Text>
        The video instruction on how to use it can be found here:{" "}
        <LinkButton
          link={{
            pathname:
              "https://cdne-hermes-data-hp.azureedge.net/Hermes-Request.mp4",
          }}
          target="_blank"
          caption="How to create a request to send a parcel or support in delivering it?"
        />
      </Text>
    </RichTextView>
  );
}

export function ResponseText() {
  return (
    <RichTextView>
      <h5>How to respond to an already created request?</h5>
      <ol>
        <li>
          Access the <b>Type</b> filter on the filter area and select:
          <ul>
            <li>
              <b>Courier</b> if you're seeking an EPAMer to transport your
              parcel,
            </li>
            <li>
              <b>Sender</b> if you're available to help with transporting
              parcels.
            </li>
          </ul>
        </li>
        <li>
          If needed, adjust additional search parameters and click the
          <b> Submit </b>
          button.
        </li>
        <li>
          Find a request you can assist with and click the <b>I can help </b>
          button, then confirm in the popup.
        </li>
      </ol>
      <Text>
        Once you've done this, the EPAMer who posted the original request will
        receive your contact details and get in touch with you.
      </Text>
      <Text>
        The video instruction on how to use it can be found here:{" "}
        <LinkButton
          link={{
            pathname:
              "https://cdne-hermes-data-hp.azureedge.net/Hermes-Response.mp4",
          }}
          target="_blank"
          caption="How to respond to an already created request?"
        />
      </Text>
    </RichTextView>
  );
}

export function UserInfoText() {
  return (
    <RichTextView>
      <FlexRow cx="guideTab">
        <h5>How to view accepted requests?</h5>
        <FlexRow>
          <FlexCell width={"auto"} alignSelf="start">
            <Text>
              To access the User page, simply click on the corresponding icon.
              (1)
            </Text>
            <Text>
              You can add an additional personal email address, where copies of
              responses to your requests will be sent. (2)
            </Text>
            <Text>
              You can view any notifications that have been sent to you. (3)
            </Text>
          </FlexCell>
          <FlexCell width={"auto"}>
            <img
              src="https://cdne-hermes-data-hp.azureedge.net/image.png"
              width={"440px"}
            />
          </FlexCell>
        </FlexRow>
      </FlexRow>
    </RichTextView>
  );
}
