import React, {useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import Container from '@mui/material/Container';
import logo from '../../hermeslogo.png'
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import {baseUrlFunc} from "../Constants";


const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 0),
    },
}));

export default function SignIn(props) {
    const classes = useStyles();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);

    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar src={logo}/>
                <Typography component="h1" variant="h5">
                    Hermes
                </Typography>
                <Button
                    fullWidth
                    variant="contained"
                    color="success"
                    className={classes.submit}
                    href={`${baseUrlFunc()}oauth2/authorization/epam`}
                >
                    Sign In with SSO
                </Button>
                <Accordion className={classes.form}>
                    <AccordionSummary>
                        <Typography>Use "Sign In with SSO" button above to sign in</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <form className={classes.form} onSubmit={() => props.onClick(username, password)}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                value={username}
                                onChange={handleUsernameChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={handlePasswordChange}
                            />
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => props.onClick(username, password)}
                            >
                                Sign In
                            </Button>
                        </form>
                    </AccordionDetails>
                </Accordion>
            </div>
        </Container>
    );
}
