import React from "react";
import { render } from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import Copyright from "./app/screens/util/Copyright";
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";
import {
  UuiContext,
  HistoryAdaptedRouter,
  useUuiServices,
  DragGhost,
  IHistory4,
  UuiContexts,
} from "@epam/uui-core";
import { Modals, Snackbar } from "@epam/uui-components";
import { ErrorHandler } from "@epam/promo";
import { createBrowserHistory } from "history";
import { TApi, getApi } from "./app/api/api";
import "@epam/uui-components/styles.css";
import "@epam/uui/styles.css";
import "@epam/loveship/styles.css";

const history = createBrowserHistory() as unknown as IHistory4;
const router = new HistoryAdaptedRouter(history);

function UuiEnhancedApp() {
  const { services } = useUuiServices<TApi, UuiContexts>({
    apiDefinition: () => getApi(),
    router,
  });

  return (
    <UuiContext.Provider value={services}>
      <ErrorHandler>
        <DevSupport
          ComponentPreviews={ComponentPreviews}
          useInitialHook={useInitial}
        >
          <App />
        </DevSupport>
      </ErrorHandler>
      <Snackbar />
      <Modals />
      <DragGhost />
    </UuiContext.Provider>
  );
}

render(<UuiEnhancedApp />, document.getElementById("root"));

render(<Copyright />, document.getElementById("footer"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
