import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import React, {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {api} from "../api/api";
import {generateReceiverKey} from "../screens/component/ReceiverInSendList";

export default function ReceiverAutocomplete(props) {

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;

    const [selected, setSelected] = useState(null);

    useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        api.receiver.getBaseReceivers()
            .then(receivers => {
                if (active) {
                    setOptions(receivers);
                }
            })
            .catch((error) => {
                props.onFetchFailed(error);
            })

        return () => {
            active = false;
        };
    }, [loading]);


    return <Autocomplete
        id="receiver"
        style={{width: 300}}
        open={open}
        onOpen={() => {
            setOpen(true);
        }}
        onClose={() => {
            setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => generateReceiverKey(option) === generateReceiverKey(value)}
        getOptionLabel={option => option.name}
        options={options.sort((a, b) => a.name.localeCompare(b.name))}
        loading={loading}
        getOptionDisabled={option => props.selectedReceivers.includes(option)}
        value={selected}
        onChange={(event, newValue) => {
            setSelected(newValue);
            props.handleReceiverAdd(newValue);
            setSelected(null);

        }}
        clearOnEscape
        renderOption={(props, option) => (
            <Box component="li" {...props} key={generateReceiverKey(option)}>
                {option.name} {option.alternative ? "*" : ""}
            </Box>
        )}
        renderInput={params => (
            <TextField
                {...params}
                label="Select receiver"
                fullWidth
                variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20}/> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        )}
    />
}
