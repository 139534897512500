import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TablePaginationActions from "./TablePaginationActions";
import { Paper, TableCell } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import { api } from "../../api/api";
import { debounce } from "lodash";

export default function TableView({
  data,
  setData,
  totalUrl,
  fetchUrl,
  onFetchFailed,
  reload,
  setReload,
  tableHeader,
  tableRows,
  sortDirection,
  sort,
  filter,
  methodType,
  skeletonList,
  searchValue,
}) {
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(data.length === 0);

  const headerColumnsCount = skeletonList
    ? skeletonList.length
    : tableHeader.props.children.length;

  const loadDataDebounce = debounce(
    (page, limit, sortDirection, sort, filter) => {
      setLoading(true);
      loadData(page, limit, sortDirection, sort, filter);
    },
    500
  );

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setLoading(true);
    setPage(newPage);
    loadDataDebounce(newPage, rowsPerPage, sortDirection, sort, filter);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoading(true);
    let rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    setPage(0);
    loadDataDebounce(0, rowsPerPage, sortDirection, sort, filter);
  };

  const getTotal = async (filter) => {
    try {
      const text = await api.table.getTotal(totalUrl, methodType, filter, searchValue);
      return parseInt(text, 10);
    } catch (error) {
      onFetchFailed(error);
    }
  };

  const loadDataWithReset = async (
    page,
    limit,
    resetStart,
    sortDirection,
    sort,
    filter
  ) => {
    const startElement = resetStart ? 0 : data.length;
    const lastElement = (page + 1) * limit;
    if (data.length < lastElement) {
      const body = {
        sort: {
          sort,
          sortDirection,
        },
        filter,
      };
      try {
        const json = await api.table.getContent(
          fetchUrl,
          startElement,
          limit,
          methodType,
          methodType ? body : undefined,
          searchValue,
        );
        if (resetStart) {
          setData(json.content);
          setPage(0);
        } else {
          const newData = data.slice(0, data.length);
          newData.push(...json.content);
          setData(newData);
        }
      } catch (error) {
        onFetchFailed(error);
      }
    }
  };

  const loadData = async (page, limit, sortDirection, sort, filter) => {
    const newTotal = await getTotal(filter);
    if (newTotal !== total) {
      setTotal(newTotal);
    }
    await loadDataWithReset(page, limit, false, sortDirection, sort, filter);
    setLoading(false);
  };

  useEffect(() => {
    if (searchValue === undefined || loading) {
      return undefined;
    }
    setPage(0);
    setTotal(0);
    setData([]);
    setLoading(true);
  }, [sortDirection, sort, filter, searchValue]);

  useEffect(() => {
    if (searchValue === undefined || loading) {
      return undefined;
    }
    setPage(0);
    setTotal(0);
    setData([]);
    setLoading(true);
    setReload(false);
  }, [reload]);

  useEffect(() => {
    if (!loading) {
      return undefined;
    }
    (async () => {
      if (data.length === 0) {
        await loadData(page, rowsPerPage, sortDirection, sort, filter);
      }
    })();
  }, [loading]);

  const skeletonProps = skeletonList
    ? skeletonList
    : new Array(headerColumnsCount).fill(null);
  return (
    <div>
      <Paper>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>{tableHeader}</TableHead>
          {loading ? (
            <TableBody>
              {new Array(3).fill(null).map((v, idx) => {
                return (
                  <TableRow key={idx}>
                    {skeletonProps.map((skeleton, i) => (
                      <TableCell key={i} sx={{ width: skeleton?.width }}>
                        <Skeleton height={"48px"} />
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <>
              <TableBody>
                {(rowsPerPage > 0
                  ? data.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : data
                ).map((row, index) => tableRows(row, index))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      3,
                      10,
                      50,
                      100,
                      { label: "All", value: total },
                    ]}
                    colSpan={4}
                    count={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </>
          )}
        </Table>
      </Paper>
    </div>
  );
}
