import React from "react";
import { useArrayDataSource } from "@epam/uui-core";
import {
  FlexRow,
  LabeledInput,
  Button,
  useForm,
  FlexCell,
  PickerInput,
  DataPickerRow,
  PickerItem,
  TextInput,
} from "@epam/uui";
import { Country } from "../../../dialog/couriers/components/LocationForm/LocationForm";
import isOther from "../../utils/isOther";

import "./FilterBar.css";

export default function FilterBar({ onSubmit, countryList, sizeList }: any) {
  const courierTypes = [
    { id: "COURIER", type: "Courier" },
    { id: "SENDER", type: "Sender" },
  ];

  const { lens, save } = useForm<any>({
    value: {},
    onSave: ({
      courierType,
      size,
      sourceCountry,
      destinationCountry,
      sourceCity,
      destinationCity,
      sourceCountryCustom,
      sourceCityCustom,
      destinationCountryCustom,
      destinationCityCustom,
    }) => {
      onSubmit({
        courierType,
        size,
        sourceCountry: isOther(sourceCountry?.name)
          ? sourceCountryCustom
          : sourceCountry?.name,
        sourceCity: isOther(sourceCity?.name)
          ? sourceCityCustom
          : sourceCity?.name,
        destinationCountry: isOther(destinationCountry?.name)
          ? destinationCountryCustom
          : destinationCountry?.name,
        destinationCity: isOther(destinationCity?.name)
          ? destinationCityCustom
          : destinationCity?.name,
      });
      return Promise.resolve({});
    },
    beforeLeave: null,
  });

  const onClear = () => {
    lens.set({});
    onSubmit({});
  };

  const getCityList = (cityType: string) => {
    const sourceCity = lens.prop(cityType).toProps().value;
    const cityList = countryList.filter(
      (country: Country) => country.countryCode === sourceCity?.id
    )?.[0];
    return cityList?.cityList;
  };

  const courierTypeDataSource = useArrayDataSource(
    {
      items: courierTypes,
    },
    []
  );

  const countriesDataSource = useArrayDataSource(
    {
      items: countryList,
    },
    []
  );

  const sizeDataSource = useArrayDataSource(
    {
      items: sizeList,
    },
    []
  );

  const sourceCityDataSource = useArrayDataSource(
    {
      items: getCityList("sourceCountry"),
    },
    []
  );

  const destinationCityDataSource = useArrayDataSource(
    {
      items: getCityList("destinationCountry"),
    },
    []
  );

  const renderCountryRow = (props: any) => (
    <DataPickerRow
      {...props}
      key={props.rowKey}
      alignItems="center"
      renderItem={(item: any) => {
        const dataPickerProps = !isOther(item.name)
          ? { title: item.name, avatarUrl: `https://${item.flagIconUrl}` }
          : { title: item.name };
        return <PickerItem {...props} {...dataPickerProps} />;
      }}
    />
  );

  const renderNotFound = (id: string) => (props: any) =>
    (
      <DataPickerRow
        {...props}
        key="other"
        alignItems="left"
        id="other"
        value={{
          name: "Other",
          id: "other",
        }}
        isSelectable
        onClick={() => {
          lens.prop(id).set({
            name: "Other",
            id: "other",
          });
          props.onClose();
        }}
        cx="pickerNotFound"
        renderItem={() => {
          return <PickerItem {...props} title="Other" cx="pickerNotFound" />;
        }}
      />
    );

  return (
    <FlexRow
      vPadding="12"
      justifyContent="space-between"
      columnGap={12}
      cx="filterContainer"
    >
      <FlexCell width="auto">
        <LabeledInput label="Type" {...lens.prop("courierType").toProps()}>
          <PickerInput
            {...lens.prop("courierType").toProps()}
            selectionMode="single"
            valueType="id"
            dataSource={courierTypeDataSource}
            getName={(item) => item.type}
            placeholder="Type"
            disableClear
          />
        </LabeledInput>
      </FlexCell>
      <FlexCell width="auto">
        <LabeledInput
          label="From Country"
          {...lens.prop("sourceCountry").toProps()}
        >
          <PickerInput
            {...lens.prop("sourceCountry").toProps()}
            selectionMode="single"
            valueType="entity"
            dataSource={countriesDataSource}
            getName={(item) => item.name}
            renderRow={renderCountryRow}
            placeholder="From Country"
            onValueChange={(value) => {
              lens.prop("sourceCountry").set(value);
              lens.prop("sourceCity").set(null);
            }}
            disableClear
            renderNotFound={renderNotFound("sourceCountry")}
          />
        </LabeledInput>
        {isOther(lens.prop("sourceCountry").get()?.id) && (
          <LabeledInput
            label="Specify source country"
            {...lens.prop("sourceCountryCustom").toProps()}
          >
            <TextInput {...lens.prop("sourceCountryCustom").toProps()} />
          </LabeledInput>
        )}
      </FlexCell>
      <FlexCell width="auto">
        <LabeledInput label="From City" {...lens.prop("sourceCity").toProps()}>
          <PickerInput
            {...lens.prop("sourceCity").toProps()}
            selectionMode="single"
            valueType="entity"
            dataSource={sourceCityDataSource}
            getName={(item) => item.name}
            placeholder="From City"
            disableClear
            renderNotFound={renderNotFound("sourceCity")}
          />
        </LabeledInput>
        {isOther(lens.prop("sourceCity").get()?.id) && (
          <LabeledInput
            label="Specify source city"
            {...lens.prop("sourceCityCustom").toProps()}
          >
            <TextInput {...lens.prop("sourceCityCustom").toProps()} />
          </LabeledInput>
        )}
      </FlexCell>
      <FlexCell width="auto">
        <LabeledInput
          label="To Country"
          {...lens.prop("destinationCountry").toProps()}
        >
          <PickerInput
            {...lens.prop("destinationCountry").toProps()}
            selectionMode="single"
            valueType="entity"
            dataSource={countriesDataSource}
            getName={(item) => item.name}
            renderRow={renderCountryRow}
            placeholder="To Country"
            onValueChange={(value) => {
              lens.prop("destinationCountry").set(value);
              lens.prop("destinationCity").set(null);
            }}
            disableClear
            renderNotFound={renderNotFound("destinationCountry")}
          />
        </LabeledInput>
        {isOther(lens.prop("destinationCountry").get()?.id) && (
          <LabeledInput
            label="Specify destination country"
            {...lens.prop("destinationCountryCustom").toProps()}
          >
            <TextInput {...lens.prop("destinationCountryCustom").toProps()} />
          </LabeledInput>
        )}
      </FlexCell>
      <FlexCell width="auto">
        <LabeledInput
          label="To City"
          {...lens.prop("destinationCity").toProps()}
        >
          <PickerInput
            {...lens.prop("destinationCity").toProps()}
            selectionMode="single"
            valueType="entity"
            dataSource={destinationCityDataSource}
            getName={(item) => item.name}
            placeholder="To City"
            disableClear
            renderNotFound={renderNotFound("destinationCity")}
          />
        </LabeledInput>
        {isOther(lens.prop("destinationCity").get()?.id) && (
          <LabeledInput
            label="Specify destination city"
            {...lens.prop("destinationCityCustom").toProps()}
          >
            <TextInput {...lens.prop("destinationCityCustom").toProps()} />
          </LabeledInput>
        )}
      </FlexCell>
      <FlexCell width="auto">
        <LabeledInput label="Size" {...lens.prop("size").toProps()}>
          <PickerInput
            {...lens.prop("size").toProps()}
            selectionMode="single"
            valueType="id"
            dataSource={sizeDataSource}
            getName={(item) => item.name}
            placeholder="Size"
            disableClear
          />
        </LabeledInput>
      </FlexCell>
      <FlexRow columnGap={12} cx="filterBtn">
        <Button onClick={save} caption="Submit" />
        <Button
          onClick={onClear}
          caption="Clear"
          color="secondary"
          fill="none"
        />
      </FlexRow>
    </FlexRow>
  );
}
