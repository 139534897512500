import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ReceiverAutocomplete from "../../components/ReceiverAutocomplete";
import DialogContent from "@mui/material/DialogContent";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ReceiverInSendList, {generateReceiverKey} from "../component/ReceiverInSendList";
import {arrayRemove} from "../util/Everything";
import {api} from "../../api/api";
import {useSnackbar} from "notistack";

export default function SecretSantaAddCustomPresentDialog(props) {

    const [selectedReceivers, setSelectedReceivers] = useState([]);
    const {enqueueSnackbar} = useSnackbar();

    const handleReceiverAdd = (receiver) => {
        let receivers = selectedReceivers.slice(0, selectedReceivers.length);
        receivers.push(receiver);
        setSelectedReceivers(receivers);
    };

    const handleOnItemClear = (receiver) => {
        let receivers = selectedReceivers.slice(0, selectedReceivers.length);
        receivers = arrayRemove(receivers, receiver);
        setSelectedReceivers(receivers);
    };

    const handleOnClose = () =>{
        setSelectedReceivers([])
        props.handleClose()
    }

    const handleOnApprove = () =>{
        selectedReceivers.forEach(receiver =>{
            api.secretSanta.addCustomPresent(receiver.id)
                .then(text => {
                    addMessageToSnack(receiver, text);
                })
                .catch((error) => {
                    props.onFetchFailed(error);
                })
            console.log(`receiver: ${receiver.id}`)

        })
        setSelectedReceivers([])
        props.handleApprove()
    }

    const addMessageToSnack = (receiver, result) => {
        const type = result === `"OK"` ? 'success' : 'info';
        const message = result === `"OK"`
            ? `Custom present for ${receiver.name} was added`
            : `Receiver ${receiver.name} already has present`;
        snackBarAddMessage(message, type)
    };

    const snackBarAddMessage = (message, variant) => {
        enqueueSnackbar(message, {variant});
    };

    let selectedView;
    if (selectedReceivers.length > 0) {
        selectedView =
            <Paper>
                <List>
                    {selectedReceivers.map(receiver => (
                        <ReceiverInSendList key={generateReceiverKey(receiver.id)} receiver={receiver} handleOnItemClear={handleOnItemClear}
                                            emailTypes={["SANTA_PRESENT"]}
                                            handleCurrentEmailTypeChange={()=>{}}
                                            changeCurrentEmailType={false}
                        />
                    ))}
                </List>
            </Paper>
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleOnClose}
            scroll={'paper'}
        >
            <DialogTitle>{`Add custom Presents`}</DialogTitle>
            <DialogContent>
                <ReceiverAutocomplete
                    selectedReceivers={selectedReceivers}
                    handleReceiverAdd={handleReceiverAdd}
                    onFetchFailed={props.onFetchFailed}
                />
                {selectedView}
            </DialogContent>

            <DialogActions>
                <Button onClick={handleOnClose} color="primary">
                    Close
                </Button>
                <Button
                    onClick={handleOnApprove}
                    variant="contained"
                    color="secondary"
                    autoFocus
                    disabled={selectedReceivers.length === 0}
                >
                    Add Presents
                </Button>
            </DialogActions>

        </Dialog>
    );
}
