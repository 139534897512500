import React from "react";
import { useUuiContext, INotification, ItemsMap } from "@epam/uui-core";
import {
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  FlexRow,
  FlexSpacer,
  Panel,
  ScrollBars,
  Text,
  Button,
  SuccessNotification,
  ErrorNotification,
} from "@epam/uui";
import { lowerCaseAllWordsExceptFirstLetters } from "../../../../../api/helper";
import "./CourierDeactivateDialog.css";

export default function CourierDeactivateDialog(modalProps: any) {
  const svc = useUuiContext();
  const msgApx =
    modalProps.courier.courierType === "COURIER" ? "delivered" : "sent";
  const successMessage = `Yes, I've  ${msgApx} things`;
  const notSuccessMessage = `Yes, but nothing was ${msgApx}`;

  const handleDeactivate = (result: boolean) => {
    svc.api.courier
      .deactivate(modalProps.courier.id, result)
      .then(() => {
        svc.uuiNotifications
          .show((props: INotification) => (
            <SuccessNotification {...props}>
              <Text size="36" fontSize="14">
                Request was successfully deactivated
              </Text>
            </SuccessNotification>
          ))
          .catch(() => null);
      })
      .catch((error: any) => {
        svc.uuiNotifications.show((props: INotification) => (
          <ErrorNotification {...props}>
            <Text size="36" fontSize="14">
              {error}
            </Text>
          </ErrorNotification>
        ));
      });
    modalProps.onDeactivate();
    const params = { getId: (item: any) => item.id };
    const patch = ItemsMap.fromObject(
      { [modalProps.courier.id]: { ...modalProps.courier, isDeleted: true } },
      params
    );
    modalProps.onUpdate(patch);
  };
  
  return (
    <ModalBlocker
      {...modalProps}
      onClose={() => modalProps.success("")}
      disallowClickOutside
    >
      <ModalWindow cx="container">
        <Panel background="surface-main">
          <ModalHeader
            borderBottom
            title={`${lowerCaseAllWordsExceptFirstLetters(
              modalProps.courier.courierType
            )} request completion confirmation`}
            onClose={() => modalProps.success("")}
          />
          <ScrollBars hasTopShadow hasBottomShadow>
            <FlexRow padding="24">
              <Text size="36">
                Please confirm that you want to complete your request
              </Text>
            </FlexRow>
          </ScrollBars>
          <ModalFooter>
            <FlexSpacer />
            <Button
              color="secondary"
              fill="outline"
              caption="Close"
              onClick={() => modalProps.success("")}
            />
            <Button
              color="accent"
              caption={successMessage}
              onClick={() => {
                handleDeactivate(true);
                modalProps.success("");
              }}
            />
            <Button
              color="critical"
              caption={notSuccessMessage}
              onClick={() => {
                handleDeactivate(false);
                modalProps.success();
              }}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
}
