import React from "react";
import { IModal, useUuiContext } from "@epam/uui-core";
import {
  ModalBlocker,
  ModalFooter,
  ModalHeader,
  ModalWindow,
  FlexRow,
  FlexSpacer,
  Panel,
  ScrollBars,
  Text,
  Button,
} from "@epam/uui";

interface IConfirmMatchDialog extends IModal<any> {
  onCourierSubmit: () => void;
  isCourierRequest?: boolean;
}

export default function ConfirmMatchDialog(props: IConfirmMatchDialog) {
  const ctaText = props.isCourierRequest
    ? "Are you sure you are interested?"
    : "Are you sure you want to help?";
  return (
    <ModalBlocker {...props}>
      <ModalWindow>
        <Panel background="surface-main">
          <ModalHeader
            borderBottom
            title="Action Confirmation"
            onClose={() => props.success("")}
          />
          <ScrollBars hasTopShadow hasBottomShadow>
            <FlexRow padding="24">
              <Text size="36">{ctaText}</Text>
            </FlexRow>
            <FlexRow padding="24">
              <Text size="24">
                After this action, the user who created the request will be
                notified of your interest. Your e-mail address will be shared.
              </Text>
            </FlexRow>
          </ScrollBars>
          <ModalFooter>
            <FlexSpacer />
            <Button
              color="secondary"
              fill="outline"
              caption="Cancel"
              onClick={() => props.success("")}
            />
            <Button
              color="primary"
              caption="Ok"
              onClick={() => {
                props.onCourierSubmit();
                props.success("");
              }}
            />
          </ModalFooter>
        </Panel>
      </ModalWindow>
    </ModalBlocker>
  );
}
