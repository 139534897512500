import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ReceiverInSendList, {generateReceiverKey} from "../component/ReceiverInSendList";

export default function ReceiverApproveDialog(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            scroll={'paper'}
        >
            <DialogTitle>{`Please confirm sending to ${props.receivers.length} recipients`}</DialogTitle>
            <DialogContent>
                <List>
                    {props.receivers.map(receiver => (
                        <ReceiverInSendList
                            key={generateReceiverKey(receiver.id)}
                            receiver={receiver}
                            handleOnItemClear={() => props.handleOnItemClear(receiver)}
                            emailTypes={props.emailTypes}
                            changeCurrentEmailType={false}
                        />
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} color="primary">
                    Close
                </Button>
                <Button
                    onClick={props.handleApprove}
                    color="primary"
                    autoFocus
                    disabled={props.receivers.length === 0}
                >
                    Send
                </Button>
            </DialogActions>
        </Dialog>
    );
}
