import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from "@mui/material/Typography";
import SentEmailsChart from "../components/SentEmailsChart";
import {api} from "../api/api";
import {styled} from "@mui/material/styles";


const DashboardPaper = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: 240
}));

const DashboardHistory = styled(Paper)(({theme}) => ({
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
}));

export default function Dashboard(props) {
    const [countToday, setCountToday] = useState(0);
    const [countTotal, setCountTotal] = useState(0);

    useEffect(() => {
        props.setShowSearch(false);
        api.history.sentToday()
            .then(numb => {
                setCountToday(numb)
            })
        api.history.sentTotal()
            .then(numb => {
                setCountTotal(numb)
            })
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={6}>
                <DashboardPaper>
                    <Typography component="h1" variant="h2">
                        Dashboard
                    </Typography>
                </DashboardPaper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
                <DashboardPaper>
                    <Typography component="h1" variant="h5">
                        Sent today
                    </Typography>
                    <Typography component="h1" variant="h1">
                        {countToday}
                    </Typography>
                </DashboardPaper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
                <DashboardPaper>
                    <Typography component="h1" variant="h5">
                        Sent total
                    </Typography>
                    <Typography component="h1" variant="h1">
                        {countTotal}
                    </Typography>
                </DashboardPaper>
            </Grid>
            <Grid item xs={12}>
                <DashboardHistory>
                    <Typography component="h1" variant="h5">
                        History
                    </Typography>
                    <SentEmailsChart/>
                </DashboardHistory>
            </Grid>
        </Grid>
    );
}



