import React, {useEffect} from 'react';
import ReceiverDetailView from "./component/ReceiverDetailView";
import SecretSanta from "./component/SecretSanta";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import HistoryView from "./HistoryView";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },

}));

export default function UserView(props) {
    const classes = useStyles();

    useEffect(() => {
        props.setShowSearch(false);
    }, []);

    return (
        <div>
            <Paper className={classes.paper}>
                <Typography variant="h6" gutterBottom>
                    User information
                </Typography>
                <ReceiverDetailView receiverId={props.user.id} readOnly={true}
                                    {...props}
                />
            </Paper>
            <SecretSanta receiverId={props.user.id}
                         {...props}
            />
            <Paper className={classes.paper}>
                <Typography variant="h6" gutterBottom>
                    Sent notifications
                </Typography>
                <HistoryView userId={props.user.id}
                             {...props}
                />
            </Paper>

        </div>
    );
}
