import React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ContactsIcon from "@mui/icons-material/Contacts";
import PersonIcon from "@mui/icons-material/Person";
import PeopleIcon from "@mui/icons-material/People";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BuildIcon from "@mui/icons-material/Build";
import SendIcon from "@mui/icons-material/Send";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";

import Dashboard from "./screens/Dashboard";
import HistoryView from "./screens/HistoryView";
import ReceiverView from "./screens/ReceiverView";
import SenderView from "./screens/SenderView";
import UserView from "./screens/UserView";
import UsersView from "./screens/UsersView";
import AdminView from "./screens/AdminView";
import SecretSantasView from "./screens/SecretSantasView";
import { ListItem, ListItemButton } from "@mui/material";
import CouriersView from "./screens/couriers/CouriersView";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import UsersLoginView from "./screens/UsersLoginView";
import LoginIcon from '@mui/icons-material/Login';

function createListItemData(name, hName, icon, roles) {
  return { name, hName, icon, roles };
}

export const ADMIN = "ROLE_ADMIN";
export const USER = "ROLE_USER";
export const SENDER = "ROLE_SENDER";

export const getDefaultView = (user) => {
  if (user.roles.includes(ADMIN) || user.roles.includes(SENDER)) {
    return sendListItem;
  } else {
    return couriersListItem;
  }
};

let sendListItem = createListItemData("send", "Send", <SendIcon />, [
  ADMIN,
  SENDER,
]);
let couriersListItem = createListItemData(
  "couriers",
  "Couriers",
  <DeliveryDiningIcon />,
  [ADMIN, SENDER, USER]
);

export const menuData = [
  sendListItem,
  couriersListItem,
  createListItemData("history", "History", <AccessTimeIcon />, [ADMIN, SENDER]),
  createListItemData("receivers", "Receivers", <ContactsIcon />, [ADMIN]),
  createListItemData("users", "Users", <PeopleIcon />, [ADMIN]),
  createListItemData("usersLogins", "Users Logins", <LoginIcon />, [ADMIN]),
  createListItemData("secretSantas", "Secret Santas", <AcUnitIcon />, [ADMIN]),
  createListItemData("dashboard", "Dashboard", <DashboardIcon />, [
    ADMIN,
    SENDER,
  ]),
  createListItemData("admin", "Admin", <BuildIcon />, [ADMIN]),
  createListItemData("user", "User", <PersonIcon />, [ADMIN, SENDER, USER]),
];

export function ContainerItem(props) {
  return (
    <>
      {
        {
          dashboard: <Dashboard {...props} />,
          send: <SenderView {...props} />,
          history: <HistoryView {...props} />,
          receivers: <ReceiverView {...props} />,
          users: <UsersView {...props} />,
          usersLogins: <UsersLoginView {...props} />,
          user: <UserView {...props} />,
          couriers: <CouriersView {...props} />,
          secretSantas: <SecretSantasView {...props} />,
          admin: <AdminView {...props} />,
          notFound: <ErrorBoundary />,
        }[props.current?.name]
      }
    </>
  );
}

function MenuItem(props) {
  return (
    <ListItem key={props.data.name} disablePadding sx={{ display: "block" }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: props.open ? "initial" : "center",
          px: 2.5,
        }}
        onClick={() => props.onClick(props.data)}
        selected={props.currentMenu?.name === props.data.name}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: props.open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {props.data.icon}
        </ListItemIcon>
        <ListItemText
          primary={props.data.hName}
          sx={{ opacity: props.open ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  );
}

export default function MainListItems(props) {
  const menu = menuData
    .filter((item) => item.roles.some((el) => props.user.roles.includes(el)))
    .map((item) => (
      <MenuItem
        open={props.open}
        key={item.name}
        data={item}
        onClick={props.onClick}
        currentMenu={props.currentMenu}
      />
    ));

  return <div>{menu}</div>;
}
