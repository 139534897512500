import React from "react";
import dayjs from "dayjs";
import { ItemsMap, useArrayDataSource, useUuiContext } from "@epam/uui-core";
import {
  ModalBlocker,
  ModalWindow,
  FlexSpacer,
  ModalHeader,
  FlexRow,
  LabeledInput,
  TextInput,
  Button,
  ScrollBars,
  ModalFooter,
  SuccessNotification,
  useForm,
  Text,
  Panel,
  FlexCell,
  PickerInput,
  DataPickerRow,
  PickerItem,
  DatePicker,
  TextArea,
  ErrorNotification,
  Avatar,
  IconButton,
} from "@epam/uui";
import { lowerCaseAllWordsExceptFirstLetters } from "../../../api/helper";
import isOther from "../../couriers/utils/isOther";
import isInternationalType from "../../couriers/utils/IsInternational";
import { getCityValue, getCountryValue } from "../../couriers/utils/getValues";
import { ReactComponent as TableSwapOutlineIcon } from "@epam/assets/icons/table-swap-outline.svg";
import { ReactComponent as ContentLinkOutlineIcon } from "@epam/assets/icons/content-link-outline.svg";
import "./CourierDetailView.css";

const courierTypes = [
  { id: "COURIER", type: "Courier" },
  { id: "SENDER", type: "Sender" },
];

const deliveryTypes = [
  { id: "Domestic", type: "Domestic" },
  {
    id: "International",
    type: "International",
  },
];

export default function CourierCreate(modalProps) {
  const { courier } = modalProps;
  const svc = useUuiContext();

  const creatorName = courier?.creator.name;

  const courierTypeDataSource = useArrayDataSource(
    {
      items: courierTypes,
    },
    []
  );

  const deliveryTypeDataSource = useArrayDataSource(
    {
      items: deliveryTypes,
    },
    []
  );

  const getInitialValue = () => {
    return {
      courierType: courier?.courierType,
      destinationType: courier ? courier.destinationType : "Domestic",
      size: courier?.size,
      expiresAt: courier
        ? courier.expiresAt
        : dayjs().add(1, "month").format("YYYY-MM-DD").toString(),
      details: courier?.details,
      creator: creatorName,
      sourceCountry: getCountryValue(courier, "sourceCountryCode"),
      sourceCountryCustom: courier?.sourceCountry,
      destinationCountry: getCountryValue(
        courier,
        "destinationCountryCode",
        true
      ),
      destinationCountryCustom: courier?.destinationCountry,
      sourceCity: getCityValue(courier, "sourceCity", modalProps.cityList),
      // TODO: Save source city slug to BE
      sourceCitySlug: courier?.sourceCitySlug,
      sourceCityCustom: courier?.sourceCity,
      destinationCity: getCityValue(
        courier,
        "destinationCity",
        modalProps.cityList
      ),
      // TODO: Save destination city slug to BE
      destinationCitySlug: courier?.destinationCitySlug,
      destinationCityCustom: courier?.destinationCity,
    };
  };

  const { lens, save, close } = useForm({
    value: getInitialValue(),
    onSave: ({
      courierType,
      destinationType,
      sourceCountry,
      sourceCountryCustom,
      destinationCountry,
      destinationCountryCustom,
      sourceCity,
      sourceCityCustom,
      destinationCity,
      destinationCityCustom,
      size,
      expiresAt,
      details,
    }) => {
      const getCountryName = (countryCode) =>
        modalProps.countries.find(
          (country) => country.countryCode === countryCode
        )?.name;
      const courierForm = {
        id: courier?.id,
        creator: courier?.creator,
        courierType,
        destinationType,
        sourceCountryCode: !isOther(sourceCountry) ? sourceCountry : undefined,
        sourceCountry: !isOther(sourceCountry)
          ? getCountryName(sourceCountry)
          : sourceCountryCustom,
        destinationCountryCode: !isOther(destinationCountry)
          ? destinationCountry
          : undefined,
        destinationCountry: !isOther(destinationCountry)
          ? getCountryName(destinationCountry)
          : destinationCountryCustom,
        sourceCity:
          !isOther(sourceCity) && sourceCity ? sourceCity : sourceCityCustom,
        destinationCity:
          !isOther(destinationCity) && destinationCity
            ? destinationCity
            : destinationCityCustom,
        size,
        expiresAt,
        details,
      };
      svc.api.courier
        .save(courierForm)
        .then((r) => {
          const params = { getId: (item) => item.id };
          const patch = ItemsMap.fromObject({ [r.id]: r }, params);
          modalProps.onUpdate(patch);
        })
        .then(() => {
          svc.uuiNotifications
            .show((props) => (
              <SuccessNotification {...props}>
                <Text size="36" fontSize="14">
                  Request was successfully saved
                </Text>
              </SuccessNotification>
            ))
            .catch(() => null);
        })
        .catch((error) => {
          svc.uuiNotifications.show((props) => (
            <ErrorNotification {...props}>
              <Text size="36" fontSize="14">
                {error}
              </Text>
            </ErrorNotification>
          ));
        });
      return Promise.resolve({});
    },
    onSuccess: () => modalProps.success(),
    getMetadata: () => ({
      props: {
        courierType: {
          validators: [(value) => !value && "This field is mandatory"],
        },
        destinationType: {
          validators: [(value) => !value && "This field is mandatory"],
        },
        sourceCountry: {
          validators: [
            (value, courier) =>
              !isOther(courier.sourceCountry) &&
              !value &&
              "This field is mandatory",
          ],
        },
        sourceCountryCustom: {
          validators: [
            (value, courier) =>
              isOther(courier.sourceCountry) &&
              !value &&
              "This field is mandatory",
          ],
        },
        destinationCountry: {
          validators: [
            (value, courier) => [
              !value &&
                !isOther(courier.destinationCountry) &&
                isInternationalType(courier.destinationType) &&
                "This field is mandatory",
            ],
          ],
        },
        destinationCountryCustom: {
          validators: [
            (value, courier) => [
              !value &&
                isOther(courier.destinationCountry) &&
                isInternationalType(courier.destinationType) &&
                "This field is mandatory",
            ],
          ],
        },
        sourceCity: {
          validators: [
            (value, courier) =>
              (!isOther(courier.sourceCity) ||
                !isOther(courier.sourceCountry)) &&
              !courier.sourceCityCustom &&
              !value &&
              `This field is mandatory`,
          ],
        },
        sourceCityCustom: {
          validators: [
            (value, courier) =>
              (isOther(courier.sourceCity) || isOther(courier.sourceCountry)) &&
              !value &&
              "This field is mandatory",
          ],
        },
        destinationCity: {
          validators: [
            (value, courier) =>
              (!isOther(courier.destinationCity) ||
                !isOther(
                  isInternationalType(courier.destinationType)
                    ? courier.destinationCountry
                    : courier.sourceCountry
                )) &&
              !courier.destinationCityCustom &&
              !value &&
              "This field is mandatory",
          ],
        },
        destinationCityCustom: {
          validators: [
            (value, courier) =>
              (isOther(courier.destinationCity) ||
                isOther(
                  isInternationalType(courier.destinationType)
                    ? courier.destinationCountry
                    : courier.sourceCountry
                )) &&
              !value &&
              "This field is mandatory",
          ],
        },
        size: { validators: [(value) => !value && "This field is mandatory"] },
        expiresAt: {
          validators: [
            (value) =>
              !dayjs(value).isAfter(dayjs().subtract(1, "day")) &&
              "Date couldn't be in the past",
            (value) => !value && "This field is mandatory",
          ],
        },
        details: { isRequired: false },
      },
    }),
  });

  const getCityList = (cityType) => {
    const sourceCity = lens.prop(cityType).toProps().value;
    const cityList = modalProps.countries.filter(
      (country) => country.countryCode === sourceCity
    )?.[0];
    return cityList?.cityList;
  };

  const sourceCountry = lens.prop("sourceCountry").get();
  const destinationCountry = lens.prop("destinationCountry").get();
  const sourceCity = lens.prop("sourceCity").get();
  const destinationCity = lens.prop("destinationCity").get();
  const sourceCountryCustom = lens.prop("sourceCountryCustom").get();
  const destinationCountryCustom = lens.prop("destinationCountryCustom").get();
  const sourceCityCustom = lens.prop("sourceCityCustom").get();
  const destinationCityCustom = lens.prop("destinationCityCustom").get();

  const sourceCountriesDataSource = useArrayDataSource(
    {
      items: modalProps.countries.filter(
        (country) =>
          country.countryCode !== destinationCountry ||
          isOther(country.countryCode)
      ),
    },
    []
  );

  const destinationCountriesDataSource = useArrayDataSource(
    {
      items: modalProps.countries.filter(
        (country) =>
          country.countryCode !== sourceCountry || isOther(country.countryCode)
      ),
    },
    []
  );

  const sizeDataSource = useArrayDataSource(
    {
      items: modalProps.sizes,
    },
    []
  );

  const sourceCityDataSource = useArrayDataSource(
    {
      items: getCityList("sourceCountry")?.filter(
        (city) => city.id !== destinationCity || isOther(city.id)
      ),
    },
    []
  );

  const isInternational = isInternationalType(
    lens.prop("destinationType").toProps().value
  );

  const destinationCityDataSource = useArrayDataSource(
    {
      items: getCityList(
        isInternational ? "destinationCountry" : "sourceCountry"
      )?.filter((city) => {
        return city.id !== sourceCity || isOther(city.id);
      }),
    },
    []
  );

  const detailsLabel = `Here are some details of what I ${
    lens.prop("courierType").toProps().value === "COURIER"
      ? "can deliver"
      : " would like to send"
  }...`;

  //helpers
  function isDateBiggerThanToday(date) {
    const d1 = dayjs(date);
    const today = dayjs().startOf("day");

    return d1.isAfter(today) || d1.isSame(today);
  }

  const renderCountryRow = (props) => (
    <DataPickerRow
      {...props}
      key={props.rowKey}
      alignItems="center"
      renderItem={(item) => {
        const dataPickerProps = !isOther(item.name)
          ? { title: item.name, avatarUrl: `https://${item.flagIconUrl}` }
          : { title: item.name };
        return <PickerItem {...props} {...dataPickerProps} />;
      }}
    />
  );

  const renderNotFound = (id) => (props) =>
    (
      <DataPickerRow
        {...props}
        key="other"
        alignItems="left"
        value="other"
        isSelectable
        onClick={() => {
          lens.prop(id).set("other");
          props.onClose();
        }}
        cx="pickerNotFound"
        renderItem={() => {
          return (
            <PickerItem
              {...props}
              value="other"
              title="Other"
              cx="pickerNotFound"
            />
          );
        }}
      />
    );

  const modalTitle = creatorName
    ? `${lowerCaseAllWordsExceptFirstLetters(
        courier.courierType
      )} request for ${creatorName}`
    : "New courier request creation";

  const getCountryFlag = (countryCode) =>
    modalProps.countries.find((country) => country.countryCode === countryCode)
      ?.flagIconUrl;

  const sourceCountryInputProps = sourceCountry &&
    !isOther(sourceCountry) && {
      icon: () => (
        <Avatar img={`https://${getCountryFlag(sourceCountry)}`} size={24} />
      ),
    };

  const destinationCountryInputProps = destinationCountry &&
    !isOther(destinationCountry) && {
      icon: () => (
        <Avatar
          img={`https://${getCountryFlag(destinationCountry)}`}
          size={24}
        />
      ),
    };

  const swapCountries = () => {
    lens.prop("sourceCountry").set(destinationCountry);
    lens.prop("destinationCountry").set(sourceCountry);
    lens.prop("sourceCountryCustom").set(destinationCountryCustom);
    lens.prop("destinationCountryCustom").set(sourceCountryCustom);
    lens.prop("sourceCity").set();
    lens.prop("destinationCity").set();
  };

  const swapCities = () => {
    lens.prop("sourceCity").set(destinationCity);
    lens.prop("destinationCity").set(sourceCity);
    lens.prop("sourceCityCustom").set(destinationCityCustom);
    lens.prop("destinationCityCustom").set(sourceCityCustom);
  };

  const copyLinkToClipboard = () => {
    const link = window.location.href;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        svc.uuiNotifications
          .show((props) => (
            <SuccessNotification {...props}>
              <Text size="36" fontSize="14">
                Link copied to clipboard
              </Text>
            </SuccessNotification>
          ))
          .catch(() => null);
      })
      .catch((err) => {
        console.error("Failed to copy the link: ", err);
      });
  };
  return (
    <ModalBlocker
      {...modalProps}
      abort={() =>
        close()
          .then(() => modalProps.success())
          .then(() => modalProps.onClose())
          .catch((e) => console.log(e))
      }
    >
      <ModalWindow cx="courierContainer">
        <ModalHeader
          borderBottom
          title={modalTitle}
          onClose={() =>
            close()
              .then(() => modalProps.success())
              .then(() => modalProps.onClose())
              .catch((e) => console.log(e))
          }
        />
        <ScrollBars>
          <Panel background="surface-main">
            <FlexRow padding="24" vPadding="12" justifyContent="space-between">
              <FlexCell width="auto">
                <LabeledInput
                  label="I am"
                  {...lens.prop("courierType").toProps()}
                >
                  <PickerInput
                    {...lens.prop("courierType").toProps()}
                    inputCx="textInput"
                    bodyCx="dropdownBody"
                    selectionMode="single"
                    valueType="id"
                    dataSource={courierTypeDataSource}
                    getName={(item) => item.type}
                    disableClear
                    isReadonly={modalProps.isViewMode}
                  />
                </LabeledInput>
              </FlexCell>
              <FlexCell width="auto">
                <LabeledInput
                  label="Delivery type is"
                  {...lens.prop("destinationType").toProps()}
                >
                  <PickerInput
                    {...lens.prop("destinationType").toProps()}
                    selectionMode="single"
                    inputCx="textInput"
                    bodyCx="dropdownBody"
                    valueType="id"
                    dataSource={deliveryTypeDataSource}
                    getName={(item) => item.type}
                    disableClear
                    isReadonly={modalProps.isViewMode}
                    onValueChange={(value) => {
                      lens.prop("destinationType").set(value);
                      lens.prop("destinationCountry").set();
                      lens.prop("destinationCity").set();
                    }}
                  />
                </LabeledInput>
              </FlexCell>
            </FlexRow>
            <FlexRow padding="24" vPadding="12" justifyContent="space-between">
              <FlexCell width={isInternational ? "auto" : "100%"}>
                <LabeledInput
                  label="From Country"
                  {...lens.prop("sourceCountry").toProps()}
                >
                  <PickerInput
                    {...lens.prop("sourceCountry").toProps()}
                    inputCx={isInternational ? "textInput" : ""}
                    bodyCx={isInternational ? "dropdownBody" : ""}
                    selectionMode="single"
                    valueType="id"
                    dataSource={sourceCountriesDataSource}
                    getName={(item) => item.name}
                    renderRow={renderCountryRow}
                    disableClear
                    isReadonly={modalProps.isViewMode}
                    renderNotFound={renderNotFound("sourceCountry")}
                    onValueChange={(value) => {
                      lens.prop("sourceCountry").set(value);
                      lens.prop("sourceCountryCustom").set();
                      lens.prop("sourceCity").set();
                      if (!isInternational) lens.prop("destinationCity").set();
                    }}
                    {...sourceCountryInputProps}
                  />
                </LabeledInput>
              </FlexCell>
              {isInternational && (
                <IconButton
                  icon={TableSwapOutlineIcon}
                  cx="swapBtn"
                  onClick={swapCountries}
                />
              )}
              {isInternational && (
                <FlexCell width="auto">
                  <LabeledInput
                    label="To Country"
                    {...lens.prop("destinationCountry").toProps()}
                  >
                    <PickerInput
                      {...lens.prop("destinationCountry").toProps()}
                      inputCx="textInput"
                      bodyCx="dropdownBody"
                      selectionMode="single"
                      valueType="id"
                      dataSource={destinationCountriesDataSource}
                      getName={(item) => item.name}
                      renderRow={renderCountryRow}
                      disableClear
                      isReadonly={modalProps.isViewMode}
                      onValueChange={(value) => {
                        lens.prop("destinationCountry").set(value);
                        lens.prop("destinationCountryCustom").set();
                        lens.prop("destinationCity").set();
                      }}
                      renderNotFound={renderNotFound("destinationCountry")}
                      {...destinationCountryInputProps}
                    />
                  </LabeledInput>
                </FlexCell>
              )}
            </FlexRow>
            {(isOther(sourceCountry) || isOther(destinationCountry)) && (
              <FlexRow
                padding="24"
                vPadding="12"
                columnGap="24"
                justifyContent="space-between"
                cx={isInternational ? "textInputRow" : ""}
              >
                {isOther(sourceCountry) && (
                  <FlexCell
                    width={isInternational ? "auto" : "100%"}
                    cx={isInternational ? "" : "customInput"}
                  >
                    <LabeledInput
                      label="Specify source country"
                      {...lens.prop("sourceCountryCustom").toProps()}
                    >
                      <TextInput
                        {...lens.prop("sourceCountryCustom").toProps()}
                        cx={isInternational ? "textInput" : ""}
                        isReadonly={modalProps.isViewMode}
                      />
                    </LabeledInput>
                  </FlexCell>
                )}
                {isInternational && isOther(destinationCountry) && (
                  <FlexCell width="auto" cx="customInputDestination">
                    <LabeledInput
                      label="Specify destination country"
                      {...lens.prop("destinationCountryCustom").toProps()}
                    >
                      <TextInput
                        cx="textInput"
                        {...lens.prop("destinationCountryCustom").toProps()}
                        isReadonly={modalProps.isViewMode}
                      />
                    </LabeledInput>
                  </FlexCell>
                )}
              </FlexRow>
            )}
            <FlexRow
              padding="24"
              vPadding="12"
              justifyContent="space-between"
              cx="textInputRow"
            >
              <FlexCell width="auto" cx="start">
                <LabeledInput
                  label="From City"
                  {...lens
                    .prop(
                      isOther(sourceCountry) ? "sourceCityCustom" : "sourceCity"
                    )
                    .toProps()}
                >
                  {isOther(sourceCountry) ? (
                    <TextInput
                      {...lens.prop("sourceCityCustom").toProps()}
                      cx="textInput"
                      isReadonly={modalProps.isViewMode}
                    />
                  ) : (
                    <PickerInput
                      {...lens.prop("sourceCity").toProps()}
                      inputCx="textInput"
                      bodyCx="dropdownBody"
                      selectionMode="single"
                      valueType="id"
                      dataSource={sourceCityDataSource}
                      getName={(item) => item.name}
                      disableClear
                      isReadonly={modalProps.isViewMode}
                      renderNotFound={renderNotFound("sourceCity")}
                    />
                  )}
                </LabeledInput>
              </FlexCell>
              {!isInternational && (
                <IconButton
                  icon={TableSwapOutlineIcon}
                  cx="swapBtn"
                  onClick={swapCities}
                />
              )}
              <FlexCell width="auto" cx="end">
                <LabeledInput
                  label="To City"
                  {...lens
                    .prop(
                      isOther(destinationCountry)
                        ? "destinationCityCustom"
                        : "destinationCity"
                    )
                    .toProps()}
                >
                  {(!isInternational && isOther(sourceCountry)) ||
                  isOther(destinationCountry) ? (
                    <TextInput
                      {...lens.prop("destinationCityCustom").toProps()}
                      cx="textInput"
                      isReadonly={modalProps.isViewMode}
                    />
                  ) : (
                    <PickerInput
                      {...lens.prop("destinationCity").toProps()}
                      inputCx="textInput"
                      bodyCx="dropdownBody"
                      selectionMode="single"
                      valueType="id"
                      dataSource={destinationCityDataSource}
                      getName={(item) => item.name}
                      disableClear
                      isReadonly={modalProps.isViewMode}
                      renderNotFound={renderNotFound("destinationCity")}
                    />
                  )}
                </LabeledInput>
              </FlexCell>
            </FlexRow>
            {(isOther(sourceCity) || isOther(destinationCity)) && (
              <FlexRow
                padding="24"
                vPadding="12"
                justifyContent="space-between"
                columnGap="24"
                cx="textInputRow"
              >
                {isOther(sourceCity) && (
                  <FlexCell width="auto" cx="customInput">
                    <LabeledInput
                      label="Specify source city"
                      {...lens.prop("sourceCityCustom").toProps()}
                    >
                      <TextInput
                        {...lens.prop("sourceCityCustom").toProps()}
                        cx="textInput"
                        isReadonly={modalProps.isViewMode}
                      />
                    </LabeledInput>
                  </FlexCell>
                )}
                {isOther(destinationCity) && (
                  <FlexCell width="auto" cx="customInputDestination">
                    <LabeledInput
                      label="Specify destination city"
                      {...lens.prop("destinationCityCustom").toProps()}
                    >
                      <TextInput
                        {...lens.prop("destinationCityCustom").toProps()}
                        cx="textInput"
                        isReadonly={modalProps.isViewMode}
                      />
                    </LabeledInput>
                  </FlexCell>
                )}
              </FlexRow>
            )}
            <FlexRow padding="24" vPadding="12" justifyContent="space-between">
              <FlexCell width="auto">
                <LabeledInput label="Size" {...lens.prop("size").toProps()}>
                  <PickerInput
                    {...lens.prop("size").toProps()}
                    inputCx="textInput"
                    bodyCx="dropdownBody"
                    selectionMode="single"
                    valueType="entity"
                    dataSource={sizeDataSource}
                    getName={(item) => item.name}
                    disableClear
                    isReadonly={modalProps.isViewMode}
                    autoComplete
                  />
                </LabeledInput>
              </FlexCell>
              <FlexCell width="auto">
                <LabeledInput
                  label="Valid until"
                  {...lens.prop("expiresAt").toProps()}
                >
                  <DatePicker
                    {...lens.prop("expiresAt").toProps()}
                    id="expiresAt"
                    format="DD/MM/YYYY"
                    filter={(day) => isDateBiggerThanToday(day)}
                    disableClear
                    isReadonly={modalProps.isViewMode}
                    inputCx="textInput"
                  />
                </LabeledInput>
              </FlexCell>
            </FlexRow>
            <FlexRow padding="24" vPadding="24">
              <LabeledInput
                label={detailsLabel}
                {...lens.prop("details").toProps()}
              >
                <TextArea
                  {...lens.prop("details").toProps()}
                  id="details"
                  rows={2}
                  isReadonly={modalProps.isViewMode}
                />
              </LabeledInput>
            </FlexRow>
            {courier && (
              <FlexRow padding="24" vPadding="24">
                <LabeledInput
                  label="Creator Name"
                  {...lens.prop("creator").toProps()}
                >
                  <TextInput
                    {...lens.prop("creator").toProps()}
                    id="creator"
                    isReadonly
                  />
                </LabeledInput>
              </FlexRow>
            )}
          </Panel>
          {
            <ModalFooter borderTop>
              {courier && (
                <Button
                  icon={ContentLinkOutlineIcon}
                  caption="Copy link"
                  color="accent"
                  iconPosition="right"
                  onClick={copyLinkToClipboard}
                />
              )}
              <FlexSpacer />
              {!modalProps.isViewMode && (
                <Button color="primary" caption="Save" onClick={save} />
              )}
            </ModalFooter>
          }
          <FlexSpacer />
        </ScrollBars>
      </ModalWindow>
    </ModalBlocker>
  );
}
