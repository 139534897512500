import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React, {useState} from "react";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
import {MenuItem, Select} from "@mui/material";


export const generateReceiverKey = (receiver) =>{
    return  receiver.id + (receiver.alternative ? 'a' : "");
}
export default function ReceiverInSendList(props) {
    let secondary = props.receiver.alternative ? props.receiver.baseName : "";
    const [currentValue, setCurrentValue] = useState(props.receiver.emailSendType);


    const handleCurrentTypeChange = (event) => {
        let value = event.target.value
        props.handleCurrentEmailTypeChange(value)
        setCurrentValue(value)
    }

    return (
        <ListItem>
            <ListItemText  primary={props.receiver.name} secondary={secondary}/>
            <ListItemSecondaryAction>
                {props.changeCurrentEmailType ?
                    <Select
                        variant="standard"
                        value={currentValue}
                        onChange={handleCurrentTypeChange}>
                        {props.emailTypes.map((res) =>
                            <MenuItem key={res.value}
                                      value={res.value}>{res.name}</MenuItem>
                        )}
                    </Select>
                    : props.emailTypes
                        .find((res)=> res.value===props.receiver.emailSendType)
                        .name
                }
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => props.handleOnItemClear(props.receiver)}
                    size="large">
                    <ClearIcon/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
