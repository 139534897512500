const disclaimerText = {
  header: {
    text: "EPAM Systems, Inc., together with its subsidiaries (collectively, “EPAM”) kindly ask you to read this disclaimer carefully before using the Hermes module of Settlement Platform (further Hermes module).",
  },
  body: [
    {
      listBold: "No Fee Policy: ",
      listText:
        "EPAM does not expect users of the Hermes Platform to request any fee or financial compensation as a reward for the service provided. Instead, the exchange of a Hermes badge is strongly appreciated as a token of gratitude and recognition among users. This policy underscores our commitment to fostering a community of sharing and support, free of financial transactions.",
    },
    {
      listBold: "Absence of Liability for Parcel / Items Safety: ",
      listText:
        "EPAM expressly disclaims any responsibility for the safety, security, or condition of any parcels, goods, or items transported via the Hermes module. Users assume all risks associated with the transportation of their items.",
    },
    {
      listBold: "Legal Issues and Compliance: ",
      listText:
        "EPAM is not liable for any legal issues, disputes, or violations that may arise from the transportation of parcels, goods, or items through the Hermes module. Users are solely responsible for ensuring their actions comply with all applicable laws and regulations.",
    },
    {
      listBold: "Medicine and Restricted Goods: ",
      listText:
        "EPAM does not assume responsibility for the transportation of medical goods or any items subject to country-specific regulations on restricted drugs or medicine. Users are solely responsible for ensuring compliance with all health and safety regulations of the involved countries.",
    },
    {
      listBold: "Air Travel Security Regulations: ",
      listText:
        "Users of the Hermes module are solely responsible for adhering to all security regulations related to air travel, including but not limited to carry-on bag, trolley bag, and checked-in bag requirements. EPAM will not be held liable for any violations or repercussions resulting from failure to comply with these regulations.",
    },
    {
      listBold: "Customs Laws and Regulations: ",
      listText:
        "EPAM disclaims all liability for breaches of customs laws and regulations of the departure country, destination country, and any transit country. It is the user's responsibility to ensure that the transportation of their parcels complies with all applicable customs regulations.",
    },
    {
      listBold: "Lost or Stolen Items: ",
      listText:
        "EPAM is not liable for any items that are lost or stolen during transit. Users acknowledge and accept the risks associated with transporting items through the Hermes module.",
    },
    {
      listBold: "User Agreement to Terms: ",
      listText:
        "By using the Hermes Module, users acknowledge and agree to the terms outlined in this disclaimer. This signifies their understanding and acceptance of the risks and responsibilities associated with the use of the platform.",
    },
    {
      listBold: "Modification of Terms: ",
      listText:
        " EPAM reserves the right to modify the terms of this disclaimer at any time. Continued use of the Hermes Module after such modifications signifies the user's agreement to the modified terms.",
    },
    {
      listBold: "Contact Information: ",
      listText:
        "For questions or clarification about this disclaimer or the terms of use, users are encouraged to contact EPAM through the support portal.",
    },
  ],
  footer: {
    text: "By using the Hermes module, users acknowledge and agree to the terms outlined in this disclaimer. Users proceed at their own risk and agree to indemnify and hold EPAM harmless from any claims, damages, or losses resulting from the use of the Hermes module.",
  },
};

export default disclaimerText;