import styled from "styled-components";

const Title = styled.h3`
  font-weight: 500;
  font-size: 30px;
  line-height: 30px;
  color: #303240;
  text-align: center;
  margin-bottom: 0px;
`;

const Text = styled.h5`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #303240;
  text-align: center;
  margin-top: 10px;
`;

export default {
  Title,
  Text,
};
